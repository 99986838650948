/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@mui/material";
import { requestCardStyles as styles } from "./styles";
import {
  Aws,
  Azure,
  MsSqlServer,
  MySql,
  Oracle,
  PostgreSql,
} from "../../icons";

export const RequestCardIcon = ({ cloud }) => {
  const iconProps = { size: "30px" };
  const iconsMap = {
    mysql: <MySql {...iconProps} />,
    postgresql: <PostgreSql {...iconProps} />,
    sqlservice: <MsSqlServer {...iconProps} />,
    oracle: <Oracle {...iconProps} />,
    aws: <Aws {...iconProps} />,
    azure: <Azure {...iconProps} />,
  };
  return (
    iconsMap[cloud] || (
      <Box component="div" sx={styles.cloudTextBox}>
        {cloud}{" "}
      </Box>
    )
  );
};

export const CardRow = ({ keyName, value }) => {
  return (
    <Grid container>
      <Grid item xs={3} sx={styles.requestJsonKey}>
        {keyName}
      </Grid>
      <Grid item xs={9}>
        {value}
      </Grid>
    </Grid>
  );
};
