import { colors as muiColors } from "@mui/material";

export const mainStyles = {
  mainBox: { mx: "5px" },
  gridContainer: {
    mt: "-10px",
    height: "10vh",
    alignItems: "center",
  },
  viewSelectForm: {
    minWidth: "170px",
    mt: "5px",
    ml: "2px",
    paddingX: "5px",
  },
  viewSelectSelect: {
    height: "35px",
  },
  filterText: {
    mt: "3px",
    ml: "-10px",
    p: "5px",
    width: "80px",
  },
  requestCardsParentGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 450px)",
    gap: 1,
  },
};

export const requestCardStyles = {
  reverseStack: {
    display: "flex",
    flexDirection: "row",
    mb: "0px",
    pb: "0px",
    mt: "auto",
  },
  verticalStack: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  requestJsonKey: { fontWeight: 700 },
  cloudIconMainBox: {
    display: "inline",
    ml: "10px",
  },
  cloudTextBox: {
    display: "inline",
    fontSize: "large",
  },

  requestStatusTypo: (colors, status) => {
    const statusColor = {
      COMPLETED: colors.green[500],
      CLOSED: colors.red[500],
      FAILED: colors.red[500],
    };
    return {
      mt: "15px",
      fontWeight: 600,
      color: statusColor?.[status] || muiColors.amber[300],
    };
  },

  // ---------
};
export const submitRequestDialogStyles = {
  gridContainer: {
    padding: "7px",
    width: "50vw",
  },
  formControl: { width: "100%" },
};
