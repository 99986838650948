/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { TextFieldWithHelperText } from "../../../components/TextFieldWithHelperText";
import { submitRequestDialogStyles as styles } from "../styles";
import ConvoyDialog from "../../../components/ConvoyDialog";
import { useSubmitDbPatchCreation } from "../../../api/dbPatch";
import { useGetReleases } from "../../../api/releases";
import { isEqual } from "lodash";
import CloudSpecificDetails from "./CloudSpecificDetails";
import { DATA_PLANE_CLOUD_TYPE } from "../../../utils/constants";

const SubmitRequestDialog = ({
  open,
  resetDialog,
  callback,
  displayNotification,
  initPayload,
}) => {
  const [payload, setPayload] = useState({});
  const [generateSwim, setGenerateSwim] = useState(true);

  const {
    postData: submitDbPatchCreation,
    response: submitDbPatchCreationResp,
    responseStatus: submitDbPatchCreationStatus,
  } = useSubmitDbPatchCreation();

  const { postData: getReleases, response: getReleasesResp } =
    useGetReleases("tessell");

  useEffect(() => {
    if (open) {
      getReleases("?loadRepos=false");
      setPayload(structuredClone(initPayload));
      if (initPayload?.testConfig?.tenantUrl) {
        setGenerateSwim(false);
      } else {
        setGenerateSwim(true);
      }
    } else {
      setPayload({});
    }
  }, [open, initPayload]);

  const handleDialogConfirm = () => {
    if (!payload?.testConfig?.tenantUrl && !payload?.testConfig?.apiKey) {
      delete payload?.testConfig;
    }
    submitDbPatchCreation({
      ...payload,
      generateSwim: generateSwim || !isEqual(payload, initPayload),
    });
    callback();
    resetDialog();
  };

  /**
   * @param {string} label
   * @param {string[]} varPath
   * @returns {object} */
  const textFieldCommonProps = (label, varPath) => ({
    fullWidth: true,
    label: label,
    color: "secondary",
    value:
      (varPath?.[1]
        ? payload?.[varPath?.[0]]?.[varPath?.[1]]
        : payload?.[varPath?.[0]]) || "",
    onChange: (e) =>
      setPayload((prev) => ({
        ...prev,
        [varPath?.[0]]: varPath?.[1]
          ? {
              ...prev?.[varPath?.[0]],
              [varPath?.[1]]: e.target.value,
            }
          : e.target.value,
      })),
  });

  useEffect(() => {
    displayNotification(
      submitDbPatchCreationResp,
      submitDbPatchCreationStatus,
      callback
    );
  }, [submitDbPatchCreationResp, submitDbPatchCreationStatus]);

  return (
    <ConvoyDialog
      open={open}
      title="Submit DB Patch Request"
      handleDialogConfirm={handleDialogConfirm}
      resetDialog={resetDialog}
    >
      <Grid container spacing={1} sx={styles.gridContainer}>
        <Grid item xs={3}>
          <FormControl sx={styles.formControl}>
            <InputLabel size="string" color="secondary">
              Release Branch
            </InputLabel>
            <Select
              {...textFieldCommonProps("Release Branch", ["tessellRelease"])}
            >
              <MenuItem value={"main"}>main</MenuItem>
              {getReleasesResp?.response?.map((label, i) => (
                <MenuItem value={label?.release_label} key={i}>
                  {label?.release_label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Jira Id", ["jiraId"])}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Engine Name", ["patchDetails", "name"])}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Edition", ["patchDetails", "edition"])}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Release Name", [
              "patchDetails",
              "release",
            ])}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Patch Version", [
              "patchDetails",
              "patchVersion",
            ])}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Version Name", [
              "patchDetails",
              "versionName",
            ])}
          />
        </Grid>
      </Grid>
      {DATA_PLANE_CLOUD_TYPE?.map((cloud, i) => (
        <CloudSpecificDetails
          setPayload={setPayload}
          payload={payload}
          cloud={cloud.toLowerCase()}
          key={i}
        />
      ))}
    </ConvoyDialog>
  );
};

export default SubmitRequestDialog;

export const useSubmitRequestDialog = (
  callback = () => {},
  displayNotification = () => {}
) => {
  const [open, setOpen] = useState(false);
  const [initPayload, setInitPayload] = useState({ patchDetail: [{}] });

  return {
    submitRequestDialog: (
      <SubmitRequestDialog
        open={open}
        resetDialog={() => setOpen(false)}
        callback={callback}
        displayNotification={displayNotification}
        initPayload={initPayload}
      />
    ),
    setOpenSubmitRequestDialog: (request) => {
      setOpen(true);
      setInitPayload(request?.payloadJson || {});
    },
  };
};
