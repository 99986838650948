class CacheClass {
  prefix = "convoy-";

  /**
   * @param {string} key
   * @returns {string | null} */
  get(key) {
    if (!key) {
      return null;
    }
    return localStorage.getItem(this.prefix + key);
  }

  /**
   * @param {string} key
   * @param {string} value */
  set(key, value) {
    if (!key) {
      return null;
    }
    localStorage.setItem(this.prefix + key, value);
  }

  /**
   * @param {string} key */
  remove(key) {
    if (!key) {
      return null;
    }
    try {
      localStorage.removeItem(this.prefix + key);
    } catch (error) {}
  }

  clear() {
    localStorage.clear();
  }
}

const Cache = new CacheClass();
export default Cache;
