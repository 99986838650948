/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { Divider, FormControlLabel, Grid, Switch } from "@mui/material";
import { TextFieldWithHelperText } from "../../../components/TextFieldWithHelperText";
import { submitRequestDialogStyles as styles } from "../styles";
import { toTitleCase } from "../../../utils/helperFunctions";
const CloudSpecificDetails = ({ setPayload, payload, cloud }) => {
  const [enabled, setEnabled] = useState(true);

  /**
   * @param {string} label
   * @param {string[]} varPath
   * @returns {object} */
  const textFieldCommonProps = (label, varPath) => ({
    fullWidth: true,
    label: label,
    color: "secondary",
    value: payload?.clouds?.[cloud]?.[varPath?.[0]]?.[varPath?.[1]],
    onChange: (e) =>
      setPayload((prev) => ({
        ...prev,
        clouds: {
          ...prev?.clouds,
          [cloud]: {
            ...prev?.clouds?.[cloud],
            [varPath?.[0]]: {
              ...prev?.clouds?.[cloud]?.[varPath?.[0]],
              [varPath?.[1]]: e.target.value,
            },
          },
        },
      })),
  });

  useEffect(() => {
    if (!payload?.clouds?.[cloud]) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  }, [payload]);

  return (
    <Fragment>
      <Divider />
      <Grid container spacing={1} columns={12} sx={styles.gridContainer}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={enabled}
                onChange={() => {
                  setEnabled((prev) => {
                    if (prev) {
                      setPayload((prevIn) => ({
                        ...prevIn,
                        clouds: {
                          ...prevIn?.clouds,
                          [cloud]: undefined,
                        },
                      }));
                    }
                    return !prev;
                  });
                }}
              />
            }
            label={toTitleCase(cloud)}
          />
        </Grid>
        {enabled ? (
          <>
            <Grid item xs={3}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("Souce Global Name", [
                  "source",
                  "globalName",
                ])}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("Source Control Plane", [
                  "source",
                  "controlPlane",
                ])}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("Souce Tenant Id", [
                  "source",
                  "tenantId",
                ])}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("Db Service ID", [
                  "source",
                  "serviceId",
                ])}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("Test Tenant Domain", [
                  "test",
                  "tenantDomain",
                ])}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("Test Tenant Api Key", [
                  "test",
                  "apiKey",
                ])}
                type="password"
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Fragment>
  );
};

export default CloudSpecificDetails;
