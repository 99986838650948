/* eslint-disable react-hooks/exhaustive-deps */
import { tokens } from "../../theme";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import DataGridButton from "../../components/DataGridButton";
import { DB_PATCH_REPO_NAME } from "../../utils/constants";
import { simpleCardStyle } from "../../styles/cardStyles";
import { requestCardStyles as styles } from "./styles";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import { githubUrl } from "../../utils/urls";
import utilColors from "../../styles/utilColors";
import RedirectLink from "../../components/RedirectLink";
import { stackCenterGap } from "../../styles/utilStyles";
import { useCloseRequest } from "../../api/dbPatch";
import { useContext, useEffect } from "react";
import { UserContext } from "../../contexts";
import { CardRow, RequestCardIcon } from "./RequestCardUtils";

const RequestCard = ({
  request,
  setOpenSubmitRequestDialog,
  displayNotification,
  callback,
  view,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const loggedInUser = useContext(UserContext);

  const {
    postData: closeRequest,
    response: closeRequestResp,
    responseStatus: closeRequestStatus,
  } = useCloseRequest(request?.payloadJson?.requestId);

  useEffect(() => {
    displayNotification(closeRequestResp, closeRequestStatus, callback);
  }, [closeRequestResp, closeRequestStatus]);

  return (
    <Box sx={simpleCardStyle(colors)}>
      <Box sx={styles.verticalStack}>
        <CardRow
          keyName="Engine:"
          value={
            <Box component="div" sx={styles.cloudMainIconBox}>
              <RequestCardIcon
                cloud={request?.payloadJson?.patchDetails?.name}
              />
            </Box>
          }
        />
        <CardRow
          keyName="Clouds:"
          value={
            <Box component="div" sx={styles.cloudMainIconBox}>
              {Object.keys(request?.payloadJson?.clouds)
                ?.filter((item) => request?.payloadJson?.clouds?.[item])
                ?.map((item, i) => (
                  <RequestCardIcon key={i} cloud={item} />
                ))}
            </Box>
          }
        />
        <CardRow keyName="Submitted By:" value={request?.userEmail} />
        <CardRow keyName="JIRA:" value={request?.payloadJson?.jiraId} />
        <CardRow
          keyName="PR:"
          value={
            <RedirectLink
              href={`${githubUrl}/${DB_PATCH_REPO_NAME}/compare/${request?.payloadJson?.tessellRelease}...${request?.payloadJson?.jiraId}?expand=1`}
              color={utilColors.skyBlue}
            >
              <Typography variant="h5">Create/View</Typography>
            </RedirectLink>
          }
        />
        <Box sx={styles.reverseStack}>
          <Tooltip
            title={
              <>
                {request?.workflowStepsMetadata?.steps?.map((entry, i) => {
                  return (
                    <Typography
                      key={i}
                      sx={(() =>
                        entry?.status === "FAILED"
                          ? { color: colors.red[500] }
                          : {})()}
                    >
                      -- {entry?.step}
                    </Typography>
                  );
                })}
              </>
            }
          >
            <Typography
              variant="h5"
              sx={styles.requestStatusTypo(colors, request?.status)}
            >
              {request?.status}
            </Typography>
          </Tooltip>
          <Box sx={stackCenterGap} />
          {view !== "CLOSED" ? (
            <>
              <DataGridButton
                sx={dataGridButtonStyles.requestApproveButton}
                hue={colors.green}
                onClick={() => setOpenSubmitRequestDialog(request)}
                disabled={loggedInUser?.email !== request?.userEmail}
              >
                <Typography sx={dataGridButtonTypography(colors)}>
                  Resubmit
                </Typography>
              </DataGridButton>
              <DataGridButton
                sx={dataGridButtonStyles.requestApproveButton}
                hue={colors.red}
                onClick={() => closeRequest()}
                disabled={loggedInUser?.email !== request?.userEmail}
              >
                <Typography sx={dataGridButtonTypography(colors)}>
                  Close
                </Typography>
              </DataGridButton>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RequestCard;
